import React from "react";

const GoogleMap = ({ extraClass }) => {
  return (
    <div className={`google-map__${extraClass}`}>
      <iframe
        title="Cairn Office Location"
        src="https://www.google.com/maps/embed?pb=!1m14!1m12!1m3!1d3929.4882197375405!2d76.277019!3d9.9764692!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!5e0!3m2!1sen!2sin!4v1723024092600!5m2!1sen!2sin"
        className={`map__${extraClass}`}
        allowFullScreen
      ></iframe>
    </div>
  );
};

export default GoogleMap;
